import * as React from 'react'
import AboutCard from './AboutCard'
import PropTypes from 'prop-types'

const AboutCardGrid = ({ members }) => {
  return (
    <section className="mx-auto mt-10 grid w-full grid-cols-1 gap-x-4 gap-y-28 py-20 md:grid-cols-2 xl:grid-cols-3">
      {members.map((member, index) => (
        <AboutCard
          key={index}
          email={member.email}
          name={member.name}
          title={member.title}
          image={member.image}
          bio={member.bio}
        />
      ))}
    </section>
  )
}

AboutCardGrid.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      email: PropTypes.string,
      bio: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired,
    })
  ),
}

export default AboutCardGrid

import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import AboutCardGrid from '../components/AboutCardGrid'
import PropTypes from 'prop-types'
import YouTube from '../components/homepage/YouTube'
import { processGatsbyImageDataMock } from '../common/gatsbyImageData'
import EmailLink from '../components/EmailLink'

const CardLink = ({ url, month, quote }) => {
  return (
    <Link
      to={url}
      className="uppercase text-center border rounded-lg shadow-md border-gray-300 hover:shadow-lg duration-300 py-3 px-5"
    >
      <p className="font-bold">
        {month}
        <br />
        <i className="text-red-700 font-normal text-sm">{quote}</i>
      </p>
    </Link>
  )
}

const AboutUsPage = ({ data }) => {
  const {
    team: { members },
  } = data
  members.forEach(member => {
    processGatsbyImageDataMock(member.image)
  })
  const firstMember = members[0]
  const otherMembers = members.slice(1)
  return (
    <Layout className={'py-10 w-10/12 max-w-[1366px] mx-auto'} breadcrumbs={false}>
      <Seo
        title="About Gray and Sons Team and Pre-Owned and Jewelry store in Miami, FL"
        description="About Our Team, Our Master-trained watchmakers are experts at restoring watches to their original like-new condition."
        canonical="/about-us/"
      />

      <h1 className="text-4xl text-center uppercase">Our Team </h1>
      <p className="w-full mx-auto py-5">
        The primary focus of our company has always been selling meticulously restored &amp;
        like-new condition Certified Preowned watches and one-of-a-kind estate jewelry pieces, but
        we can also special order unused watches from most of the famous Swiss Brands and have an
        impressive selection of modern jewelry as well as diamonds of all types &amp; qualities.
        <br />
        <br />
        We are always acquiring&nbsp;{' '}
        <Link title="New Watches and Jewelry Pieces" to="/what-s-new.html">
          new watches and jewelry pieces
        </Link>
        , so check our website frequently, or sign up for our&nbsp;
        <Link title="Free Monthly Catalog" to="/catalog-request">
          free monthly catalog
        </Link>
        . If you have an item that you are interested in selling,&nbsp;
        <a
          title="We Can Help Sell Your Jewelry"
          href="https://sellusyourjewelry.com/"
          rel={'noopener noreferrer'}
          target="_blank"
        >
          we can help sell your piece
        </a>
        <br />
        <br />
        Our Master-trained watchmakers are experts at&nbsp;
        <Link title="Restoring Watches" to="/repairs">
          restoring watches
        </Link>{' '}
        to their original like-new condition. We are one of the preeminent locations for Miami watch
        repair - and around the world. With six in-house Master-trained watchmakers with over 150
        years combined experience to handle all your watch repairs and service issues. If you have a
        watch that is not working as it once did,&nbsp;
        <Link
          className="frontpagecopy_links"
          title="Contact Gray &amp; Sons Jewelers"
          to="/contact-us"
        >
          send it to us
        </Link>
        . You will be amazed at your new watch. Our Miami Beach showroom is located across from the
        world renowned Bal Harbour Shops, and we have been doing mail order business with customers
        around the country, and even around the world. If you are ever in the area please&nbsp;
        <Link title="Stop by Gray &amp; Sons Jewelers" to="/contact-us">
          stop by
        </Link>
        , we would love to meet you.
      </p>
      <div className="grid py-10 lg:grid-cols-[auto_1fr] lg:grid-rows-[1fr_auto] group max-w-[1000px] mx-auto w-full gap-5">
        <GatsbyImage
          alt={firstMember.name}
          image={getImage(firstMember.image)}
          className={
            'w-1/2 max-w-[300px] row-span-2 mx-auto lg:w-full border bg-white border-gray-300 rounded-full aspect-square group-hover:scale-[102%] group-hover:shadow-lg duration-300'
          }
        />
        <p className="order-2 lg:order-2 text-center lg:text-left">
          <b>{firstMember.name}</b>
          <br />
          <i>{firstMember.title}</i>
          <br />
          {firstMember.email && <EmailLink email={firstMember.email}>{firstMember.email}</EmailLink>}
        </p>
        <p className="order-2 lg:order-3" dangerouslySetInnerHTML={{ __html: firstMember.bio }}></p>
      </div>
      <div className="grid lg:grid-cols-4 xl:grid-cols-6 md:grid-cols-3 grid-cols-2 gap-2">
        <CardLink url="/its-a-journey-owner-nov-message" month="November" quote="It's a Journey!" />
        <CardLink url="/grayandsons-owner-may-message" month="May" quote="Momma Gray" />
        <CardLink
          url="/grayandsons-owner-march-message"
          month="March"
          quote="Heirloom Brilliance"
        />
        <CardLink
          url="/grayandsons-owner-february-message"
          month="February"
          quote="A Token of My Love"
        />
        <CardLink
          url="/grayandsons-owner-january-message"
          month="January"
          quote="Let's be Grateful"
        />
        <CardLink url="/keith-gray-grayandsons-owner" month="December" quote="Keeping Time" />
      </div>
      <AboutCardGrid members={otherMembers} />

      <section className="grid grid-cols-2 grid-rows-[1fr_auto] gap-4">
        <StaticImage
          src={'../images/gray-and-sons-showroom-bal-harbour-miami-beach.png'}
          alt={'Our Team'}
          className="col-span-2"
        />

        <div className="col-span-2 lg:col-span-1">
          <YouTube
            className="w-full aspect-video"
            title={'Gray and Sons - Fine Watch & Estate Jewelry Specialists'}
            src="https://www.youtube.com/embed/4CC2oR81SQk"
          />
        </div>
        <p className="max-w-[800px] mx-auto col-span-2 lg:col-span-1">
          It was 1980 when we{' '}
          <Link title="First Opened our Doors" to="/ourstory">
            first opened our doors
          </Link>
          &nbsp;for business in Miami Beach, Florida. While a lot has changed since then, there are
          fundamental principles that have governed Gray &amp; Sons since day one. Principles like
          offering only the highest quality merchandise coupled with the&nbsp;
          <Link title="First Opened our Doors" to="/returns">
            highest quality service
          </Link>
          &nbsp;all at a substantial discount, have helped us grow into what we are today.
        </p>
      </section>
    </Layout>
  )
}

CardLink.propTypes = {
  url: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
}

AboutUsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query {
    team: allStrapiTeamMember(sort: { fields: [priority, name], order: [DESC, ASC] }) {
      members: nodes {
        name
        title
        email
        bio
        image {
          gatsbyImageDataMock
        }
      }
    }
  }
`

export default AboutUsPage
